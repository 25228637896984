import { Button, FormGroup, InputGroup, Intent } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { createToaster } from "../../../../views/utils/createToaster"
import { SCIENT_ROUTES } from "../../../Routes"
import { TradingDataset } from "../Dataset/types"
import useCreateDatasetMutation from "../hooks/useCreateDatasetMutation"

interface CustomDatasetFormProps {
  autoFocus?: boolean
  onSuccess?: (dataset: TradingDataset) => void
}

const CustomDatasetForm = ({ autoFocus = false, onSuccess }: CustomDatasetFormProps) => {
  const [name, setName] = useState("")
  const [scientCode, setScientCode] = useState("")
  const [scientCodeError, setScientCodeError] = useState(false)
  const navigate = useNavigate()

  const createDatasetMutation = useCreateDatasetMutation({
    onSuccess: dataset => {
      createToaster({
        message: "Dataset created successfully",
        intent: Intent.SUCCESS,
      })
      onSuccess?.(dataset)
      navigate(`${SCIENT_ROUTES.CONFIG}/datasets/${dataset.id}`)
    },
    onError: (error: AxiosError<{ error: { message: string; code: string } }>) => {
      if (error.response?.data?.error?.code === "scient_code_already_exists") {
        createToaster({
          message: "A dataset with this scient code already exists",
          intent: Intent.DANGER,
        })
      } else {
        createToaster({
          message: "Error while creating the daset, please contact support",
          intent: Intent.DANGER,
        })
      }
    },
  })

  const validateScientCode = (code: string) => {
    const isValid = code.trim().endsWith(" Scient")
    setScientCodeError(!isValid)
    return isValid
  }

  const handleSubmit = async () => {
    if (!name.trim()) {
      createToaster({
        message: "Please enter a dataset name",
        intent: Intent.DANGER,
      })
      return
    }

    if (!validateScientCode(scientCode)) {
      return
    }

    createDatasetMutation.mutate({
      name: name.trim(),
      scient_code: scientCode.trim(),
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FormGroup label="Dataset Name" labelInfo="(required)">
        <InputGroup
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter dataset name..."
          autoFocus={autoFocus}
        />
      </FormGroup>
      <FormGroup
        label="Scient Internal Code"
        labelInfo="(required)"
        helperText="Must end with ' Scient' (leave a space before Scient)"
        intent={scientCodeError ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          value={scientCode}
          onChange={e => {
            setScientCode(e.target.value)
            validateScientCode(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          placeholder="Enter scientific code..."
          intent={scientCodeError ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <Button
        text="Create Dataset"
        intent={Intent.PRIMARY}
        onClick={handleSubmit}
        loading={createDatasetMutation.isLoading}
        disabled={!name.trim() || createDatasetMutation.isLoading}
      />
    </FlexContainer>
  )
}

export default CustomDatasetForm
