import { Icon } from "@blueprintjs/core"
import { format } from "date-fns"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SmallText } from "../../../../styled/text.styled"
import { Header, MetadataCard } from "./Metadata.styled"

interface IMetadataProps<T> {
  selectedItem: T
  metadataFields: MetadataField<T>[]
}

type MetadataField<T> = {
  headerName: string
  field: keyof T
  isDate?: boolean
}

/**
 * Shows uneditable metadata for various items
 */
const Metadata = <T,>({ selectedItem, metadataFields }: IMetadataProps<T>) => (
  <MetadataCard>
    <FlexContainer gap="10px">
      <FlexContainer alignItems="center">
        <Icon icon="info-sign" size={16} />
      </FlexContainer>
      <table>
        <tbody>
          {metadataFields.map(metadataField => {
            const value =
              metadataField.isDate && selectedItem[metadataField.field] != null
                ? format(new Date(selectedItem[metadataField.field] as string), "MM/dd/yyyy HH:mm")
                : selectedItem[metadataField.field]

            return (
              <tr key={String(metadataField.field)}>
                <Header>
                  <SmallText>{metadataField.headerName} </SmallText>
                </Header>
                <td>
                  <SmallText>{value as string | number}</SmallText>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </FlexContainer>
  </MetadataCard>
)

export default Metadata
