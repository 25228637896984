import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const PositionsContainer = styled(FlexContainerAndItem)({
  border: `1px solid ${SCIENT_COLORS.gray1}`,
  background: SCIENT_COLORS.darkGray4,
})

type PriceSourceStatusContainerProps = {
  backgroundColor: string
}

export const PriceSourceStatusContainer = styled(
  FlexContainerAndItem,
)<PriceSourceStatusContainerProps>(props => ({
  width: "12px",
  height: "12px",
  backgroundColor: props.backgroundColor,
  borderRadius: "50%",
}))
