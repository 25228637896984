import { Button, Callout, InputGroup, Intent } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { useEffect, useRef, useState } from "react"
import { FlexContainer, FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { MediumText } from "../../../../styled/text.styled"
import { createToaster } from "../../../utils/createToaster"
import { useRequestNewInstrumentsMutation } from "../hooks/useRequestNewInstrumentsMutation"
import { TickerSubmissionContainer } from "./TickerSubmission.styled"

interface TickerSubmissionProps {
  onSuccess?: () => void
  autoFocus?: boolean
}

const TickerSubmission = ({ onSuccess, autoFocus = false }: TickerSubmissionProps) => {
  const [tickers, setTickers] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const tickerRegexOnChange =
    /^[a-zA-Z0-9.]{1,15}(?:\s+(?:[a-zA-Z]{2})?(?:\s+(?:Index|Comdty|[eE][qQ][uU][iI][tT][yY]))?)?$/
  const validateTickersOnChange = (tickerList: string[]) =>
    tickerList.every(ticker => tickerRegexOnChange.test(ticker.trim()))

  /**
   * Auto focus the input when the component is mounted
   */
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }
  }, [autoFocus])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTickers(event.target.value)
    setIsValid(validateTickersOnChange(event.target.value.split(",")))
  }

  const requestNewinstrumentsMutation = useRequestNewInstrumentsMutation({
    onSuccess: () => {
      createToaster({
        message: "Request new ticker(s) with success",
        intent: Intent.SUCCESS,
      })
      setTickers("")
      setIsAddButtonClicked(false)
      onSuccess?.()
    },

    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        error?: { __all__?: string[] }
      }
      const errorMessage = errorData?.error?.__all__ || errorData?.error || error.message
      const errorType = error.response?.status

      createToaster({
        message:
          errorType === 403
            ? "You have reached the limit of requests per day. Please try again tomorrow."
            : errorMessage
              ? `Error: ${errorMessage}`
              : "An error occurred: Failed to request ticker(s)",
        intent: Intent.DANGER,
      })
    },
  })

  const handleSubmit = () => {
    const normalizedTickers = tickers
      .replace(/,\s*$/, "")
      .split(",")
      .map(ticker => {
        const cleanedTicker = ticker.replace(/\s+/g, " ").trim().toUpperCase()

        // If Index or Comdty is already present (case insensitive), return as is
        if (/(Index|Comdty)$/i.test(cleanedTicker)) {
          // Ensure proper capitalization of Index/Comdty
          return cleanedTicker.replace(
            /(Index|Comdty)$/i,
            match => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase(),
          )
        }

        // Remove existing Equity suffix if present
        const withoutEquity = cleanedTicker.replace(/\s*EQUITY\s*$/i, "")

        // For traditional equity tickers, maintain the market code requirement
        if (!/\s+(Index|Comdty)$/i.test(withoutEquity)) {
          return /\s[A-Z]{2}$/.test(withoutEquity) ? `${withoutEquity} Equity` : withoutEquity
        }

        return withoutEquity
      })

    const tickerRegexOnSubmit =
      /^[a-zA-Z0-9.]{1,15}(?:\s+(?:[a-zA-Z]{2}\s+)?(?:Equity|Index|Comdty))$/
    const valid = normalizedTickers.every(ticker => tickerRegexOnSubmit.test(ticker))
    setIsValid(valid)
    setIsAddButtonClicked(true)

    if (valid) {
      requestNewinstrumentsMutation.mutate({
        tickers: normalizedTickers,
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  useEffect(() => {
    if (isValid && isAddButtonClicked) {
      setIsAddButtonClicked(false)
    }
  }, [isAddButtonClicked, isValid])

  return (
    <TickerSubmissionContainer flexDirection="column" gap="10px">
      <FlexContainer flexDirection="row" gap="10px">
        <FlexContainerAndItem flexGrow={1}>
          <InputGroup
            inputRef={inputRef}
            placeholder="Enter Bloomberg ticker(s)..."
            value={tickers}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fill
          />
        </FlexContainerAndItem>
        <Button onClick={handleSubmit} intent={Intent.PRIMARY} icon="add" />
      </FlexContainer>
      {!isValid && isAddButtonClicked && (
        <Callout intent={Intent.PRIMARY}>
          <MediumText color={SCIENT_COLORS.blue5}>Please enter valid Bloomberg tickers:</MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>
            - Equity tickers (e.g., LR FP, ABBN SW or LR FP Equity)
          </MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>- Index tickers (e.g., SPX Index)</MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>
            - Commodity tickers (e.g., CO1 Comdty)
          </MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>
            If entering multiple tickers, separate them by commas (,).
          </MediumText>
        </Callout>
      )}
    </TickerSubmissionContainer>
  )
}

export default TickerSubmission
