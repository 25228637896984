import { Tab, Tabs } from "@blueprintjs/core"
import { useState } from "react"

import { capitalizeFirstLetter } from "../../../utils"
import Portfolio from "./Portfolio/Portfolio"
import { PortfolioManagementContainer } from "./PortfolioManagement.styled"
import Trading from "./Trading/Trading"
import { IAccountBookPairs, PortfolioDisplayTab } from "./types"
import Risks from "./Risks/Risks"

const PortfolioManagement = () => {
  const [selectedTab, setSelectedTab] = useState(PortfolioDisplayTab.POSITIONS)
  // Selected accounts/books
  const [selectedFilters, setSelectedFilters] = useState<IAccountBookPairs>([])

  return (
    <PortfolioManagementContainer flexDirection="column" selectedTab={selectedTab}>
      <Tabs
        id="pm-tabs"
        onChange={id => setSelectedTab(id as PortfolioDisplayTab)}
        selectedTabId={selectedTab}
        /**
         * renderActiveTabPanelOnly avoids warning:
         *      " AG Grid tried to call sizeColumnsToFit() but the grid is coming back
         *      with zero width, maybe the grid is not visible yet on the screen? "
         */
        renderActiveTabPanelOnly
        large
        animate={false} // prevents the indicator of the selected tab from being misplaced
      >
        <Tab
          id={PortfolioDisplayTab.POSITIONS}
          title={capitalizeFirstLetter(PortfolioDisplayTab.POSITIONS)}
          panel={
            <Portfolio
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              displayTab={PortfolioDisplayTab.POSITIONS}
            />
          }
        />
        <Tab
          id={PortfolioDisplayTab.ORDERS}
          title={capitalizeFirstLetter(PortfolioDisplayTab.ORDERS)}
          panel={
            <Portfolio
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              displayTab={PortfolioDisplayTab.ORDERS}
            />
          }
        />
        <Tab
          id={PortfolioDisplayTab.RISKS}
          title={capitalizeFirstLetter(PortfolioDisplayTab.RISKS)}
          panel={<Risks selectedFilters={selectedFilters} />}
        />
        <Tab
          id={PortfolioDisplayTab.TRADING}
          title={capitalizeFirstLetter(PortfolioDisplayTab.TRADING)}
          panel={<Trading />}
        />
      </Tabs>
    </PortfolioManagementContainer>
  )
}

export default PortfolioManagement
