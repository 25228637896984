/** @jsxImportSource @emotion/react */
import { Divider, Spinner } from "@blueprintjs/core"

import { MediumItalicText, MediumText } from "../../../../styled/text.styled"
import { DataframeData } from "../types"
import { NoDataMessage } from "./Orders.styled"
import { PositionsContainer, PriceSourceStatusContainer } from "./Positions.styled"
import Grid from "../components/Grid"
import { RefreshButton } from "./components/RefreshButton"
import useIntervalDateDistance from "./hooks/useIntervalDateDistance"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

interface IPositionsProps {
  positions?: DataframeData
  isLoading: boolean
}

const Positions = ({ positions, isLoading }: IPositionsProps) => {
  const positionsDateDistance = useIntervalDateDistance({
    date: positions?.last_updated_at,
    interval: 60 * 1000,
  })

  return (
    /**
     * PositionsContainer has flexGrow={1} to take all the remaining space in height.
     */
    <PositionsContainer flexGrow={1} alignItems="center" justifyContent="center">
      {positions ? (
        <Grid
          label="Positions"
          rows={positions.rows}
          columns={positions.columns}
          contextMenuItems={positions.context_menu_items}
        >
          {positionsDateDistance && (
            <>
              <FlexContainer gap="10px" alignItems="center">
                <MediumText fontWeight="bold">Prices Source:</MediumText>
                <FlexContainer alignItems="center" gap="5px">
                  <PriceSourceStatusContainer backgroundColor={SCIENT_COLORS.blue3} />
                  <MediumItalicText>Live (Bloomberg)</MediumItalicText>
                </FlexContainer>
                <FlexContainer alignItems="center" gap="5px">
                  <PriceSourceStatusContainer backgroundColor={SCIENT_COLORS.violet5} />
                  <MediumItalicText>Delayed (Bloomberg)</MediumItalicText>
                </FlexContainer>
                <FlexContainer alignItems="center" gap="5px">
                  <PriceSourceStatusContainer backgroundColor={SCIENT_COLORS.white} />
                  <MediumItalicText>Historical (Bloomberg)</MediumItalicText>
                </FlexContainer>
              </FlexContainer>
              <Divider style={{ height: "30px" }} />
              <MediumItalicText>Last updated {positionsDateDistance}</MediumItalicText>
              <RefreshButton dateDistance={positionsDateDistance} />
            </>
          )}
        </Grid>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <NoDataMessage>
          No positions available, please select an account and/or a book
        </NoDataMessage>
      )}
    </PositionsContainer>
  )
}

export default Positions
