import { AgGridReact } from "ag-grid-react"
import { useCallback, useMemo, useRef } from "react"

import { CellClassParams, GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community"
import { ContextMenuItem, RowStyle } from "../../types"
import {
  AgGridFilter,
  ScientCellStyle,
  ScientRowStyle,
  ScientValueFormatter,
  columnsDefValueFormatterMapping,
  getCellStyleStrategy,
  rowStyleMapping,
} from "../agGridMapping"

interface IUseAgGridProps {
  columns: {
    field: string
    filter?: AgGridFilter | boolean
    valueFormatter: ScientValueFormatter
    cellStyle: ScientCellStyle
    cellStyleThreshold?: number
    hide?: boolean
    headerTooltip?: string
    tooltipField?: string
    export_included?: boolean
  }[]
  pinnedRowStyles?: {
    rowIndex: number
    rowStyle: string
  }[]
  contextMenuItems?: ContextMenuItem[]
  cancelOrderHandler?: (orderId: string, side: string, quantity: string, bBSymbol: string) => void
}

/**
 * Builds and returns the context menu items for AG Grid
 */
const buildContextMenuItems = (
  params: GetContextMenuItemsParams,
  contextMenuItems: ContextMenuItem[] = [],
  columnsData: any[],
  cancelOrderHandler?: (orderId: string, side: string, quantity: string, bBSymbol: string) => void,
  gridRef?: React.RefObject<AgGridReact>,
): (string | MenuItemDef)[] => {
  // Our custom menu items array
  const customContextMenuItems: (string | MenuItemDef)[] = []

  // Get row data from the clicked row, if available
  const rowData = params.node?.data

  // Only add Cancel Order option if we have an order_id field, this is an active order,
  // and CANCEL_ORDER is in the contextMenuItems
  if (
    contextMenuItems.includes(ContextMenuItem.CANCEL_ORDER) &&
    rowData &&
    typeof rowData["Order Id"] === "string" &&
    rowData["Order Id"].length > 0 &&
    rowData["Status"] !== "Canceled"
  ) {
    customContextMenuItems.push({
      name: "Cancel Order",
      action: () => {
        if (cancelOrderHandler && rowData["Order Id"]) {
          // Pass additional order information: side, quantity, and symbol
          cancelOrderHandler(
            rowData["Order Id"],
            rowData["Side"],
            rowData["Qty"],
            rowData["BBSymbol"],
          )
        }
      },
    })
  }

  // Add separator if we have any custom items
  if (customContextMenuItems.length > 0) {
    customContextMenuItems.push("separator")
  }

  // Add export options if it's in the contextMenuItems
  if (contextMenuItems.includes(ContextMenuItem.EXPORT)) {
    // Filter columns that should be included in export
    const exportColumnKeys = columnsData
      .filter(column => column.export_included !== false)
      .map(column => column.field)

    // Add CSV export option
    customContextMenuItems.push({
      name: "Export to CSV",
      action: () => {
        if (gridRef?.current?.api) {
          const exportParams = {
            columnKeys: exportColumnKeys,
          }
          gridRef.current.api.exportDataAsCsv(exportParams)
        }
      },
    })

    // Add Excel export option
    customContextMenuItems.push({
      name: "Export to Excel",
      action: () => {
        if (gridRef?.current?.api) {
          const exportParams = {
            columnKeys: exportColumnKeys,
          }
          gridRef.current.api.exportDataAsExcel(exportParams)
        }
      },
    })
  }

  return customContextMenuItems
}

/**
 * Custom hook to handle ag grid resizing and columns data from Scient backend
 */
export const useAgGrid = ({
  columns,
  pinnedRowStyles,
  contextMenuItems = [ContextMenuItem.EXPORT],
  cancelOrderHandler,
}: IUseAgGridProps) => {
  const gridRef = useRef<AgGridReact | null>(null)

  const sizeColumnsToFit = useCallback(() => {
    gridRef.current?.api.sizeColumnsToFit()
  }, [])

  /**
   * Memoized columns data
   * "Account", "Book" and "BBSymbol" are not included because they are grouped into Path in order to build the treeData.
   */
  const columnsData = useMemo(() => {
    return columns.map(column => {
      return {
        headerName: column.field,
        field: column.field,
        filter: column.filter,
        valueFormatter: columnsDefValueFormatterMapping[column.valueFormatter],
        cellStyle: getCellStyleStrategy(column.cellStyle, column.cellStyleThreshold),
        hide: column.hide,
        headerTooltip: column.headerTooltip,
        tooltipField: column.tooltipField,
        menuTabs: column.filter ? ["filterMenuTab"] : [],
        export_included: column.export_included,
      }
    })
  }, [columns])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    }
  }, [])

  const getRowStyle = useCallback(
    (params: CellClassParams) => {
      /**
       * In Risks Exposures tables only the Total line pinned at the botttom is highlighted.
       */
      if (params.node.rowPinned === "bottom") {
        const styleObject = pinnedRowStyles?.find(style => style.rowIndex === params.node.rowIndex)

        if (styleObject) {
          const rowStyleKey = styleObject.rowStyle as ScientRowStyle
          return rowStyleMapping[rowStyleKey] as RowStyle
        }

        // Default styling if no matching rowIndex is found
        return undefined
      }
    },
    [pinnedRowStyles],
  )

  /**
   * Generate context menu items for the ag-grid
   */
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      return buildContextMenuItems(
        params,
        contextMenuItems,
        columnsData,
        cancelOrderHandler,
        gridRef,
      )
    },
    [contextMenuItems, columnsData, cancelOrderHandler],
  )

  return {
    sizeColumnsToFit,
    columnsData,
    defaultColDef,
    gridRef,
    getRowStyle,
    getContextMenuItems,
  }
}
