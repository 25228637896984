import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../../hooks/useApi"

/**
 * Hook for canceling an order in FlexTrade
 */
const useCancelOrderMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: { success: boolean; status_code: string; description: string }) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.trading.cancelOrder, { onSuccess, onError })
}

export { useCancelOrderMutation }
