import { Dialog, Icon, Position, Tooltip } from "@blueprintjs/core"
import { Dispatch, SetStateAction } from "react"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import TickerSubmission from "./TickerSubmission"
import { DialogContainer } from "./AddNewTickerDialog.styled"

interface AddNewTickerDialogProps {
  isOpenDialog: boolean
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>
  title: string
}

const AddNewTickerDialog = ({ isOpenDialog, setIsOpenDialog, title }: AddNewTickerDialogProps) => {
  const handleClose = () => {
    setIsOpenDialog(false)
  }

  return (
    <Dialog
      className="bp5-dark"
      title={
        <FlexContainer gap="10px">
          <MediumText fontWeight="bold">{title}</MediumText>
          <Tooltip
            content="Add one or multiple ticker(s) separated by a comma"
            placement={Position.TOP}
          >
            <Icon icon="info-sign" />
          </Tooltip>
        </FlexContainer>
      }
      icon="plus"
      isOpen={isOpenDialog}
      onClose={handleClose}
    >
      <DialogContainer>
        <TickerSubmission onSuccess={handleClose} autoFocus={true} />
      </DialogContainer>
    </Dialog>
  )
}

export default AddNewTickerDialog
