import { rowClassRules } from "../../Desks/Sheet/NextGenTimeFrame/utils/classRules"
import FullScreenGrid from "../Portfolio/components/FullScreenGrid"
import { ContextMenuItem } from "../types"
import { AgGridFilter } from "./agGridMapping"
import { ScientValueFormatter } from "./agGridMapping"
import { ScientCellStyle } from "./agGridMapping"
import { useAgGrid } from "./hooks/useAgGrid"

export interface IGridProps {
  /**
   * Rows and columns to be displayed in ag-grid
   */
  rows: Record<string, string | number | null>[]
  columns: {
    field: string
    filter: AgGridFilter | boolean
    valueFormatter: ScientValueFormatter
    cellStyle: ScientCellStyle
    hide?: boolean
  }[]
  /**
   * Label to be displayed on top of the grid container
   * Info to be displayed on top right of the grid container
   * through FullScreen Children
   */
  label?: string
  children?: React.ReactNode
  contextMenuItems?: ContextMenuItem[]
  cancelOrderHandler?: (orderId: string, side: string, quantity: string, bBSymbol: string) => void
}

const Grid = ({
  rows,
  columns,
  label,
  children,
  contextMenuItems,
  cancelOrderHandler,
}: IGridProps) => {
  const { gridRef, sizeColumnsToFit, defaultColDef, columnsData, getContextMenuItems } = useAgGrid({
    columns,
    contextMenuItems,
    cancelOrderHandler,
  })

  return (
    <FullScreenGrid
      label={label}
      ref={gridRef}
      rowClassRules={rowClassRules}
      rowData={rows}
      columnDefs={columnsData}
      defaultColDef={defaultColDef}
      onFirstDataRendered={sizeColumnsToFit}
      suppressColumnVirtualisation={true}
      rowHeight={25}
      suppressCellFocus={true}
      getContextMenuItems={getContextMenuItems}
    >
      {children}
    </FullScreenGrid>
  )
}

export default Grid
