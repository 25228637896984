import { Dialog, Icon, Position, Tooltip, Tab, Tabs } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useState } from "react"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import TickerSubmission from "./TickerSubmission"
import { DialogContainer } from "./AddNewTickerDialog.styled"
import CustomDatasetForm from "./CustomDatasetForm"

interface AddNewDatasetDialogProps {
  isOpenDialog: boolean
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>
  title: string
}

const AddNewDatasetDialog = ({
  isOpenDialog,
  setIsOpenDialog,
  title,
}: AddNewDatasetDialogProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>("ticker")

  const handleClose = () => {
    setIsOpenDialog(false)
    setSelectedTabId("ticker") // Reset to default tab when closing
  }

  return (
    <Dialog
      className="bp5-dark"
      title={
        <FlexContainer gap="10px">
          <MediumText fontWeight="bold">{title}</MediumText>
          <Tooltip
            content={
              selectedTabId === "ticker"
                ? "Add one or multiple ticker(s) separated by a comma"
                : "Create a custom internal dataset"
            }
            placement={Position.TOP}
          >
            <Icon icon="info-sign" />
          </Tooltip>
        </FlexContainer>
      }
      icon="plus"
      isOpen={isOpenDialog}
      onClose={handleClose}
    >
      <DialogContainer justifyContent="center">
        <Tabs
          id="dataset-tabs"
          selectedTabId={selectedTabId}
          onChange={newTabId => setSelectedTabId(newTabId as string)}
        >
          <Tab
            id="ticker"
            title="Add Ticker"
            panel={
              <TickerSubmission onSuccess={handleClose} autoFocus={selectedTabId === "ticker"} />
            }
          />
          <Tab
            id="custom"
            title="Create Custom Dataset"
            panel={
              <CustomDatasetForm
                onSuccess={() => {
                  setIsOpenDialog(false)
                }}
                autoFocus={selectedTabId === "custom"}
              />
            }
          />
        </Tabs>
      </DialogContainer>
    </Dialog>
  )
}

export default AddNewDatasetDialog
