import { MenuItem, Spinner } from "@blueprintjs/core"
import { ItemPredicate, ItemRendererProps, Select } from "@blueprintjs/select"
import { useController, UseControllerProps } from "react-hook-form"

import { ToastError } from "../../../components"
import useTradingCountries from "../hooks/useTradingCountries"
import { Country } from "../types"
import { SelectButton } from "./SelectComponent.styled"

export const CountrySelect = <T extends Record<string, any>>({
  control,
  name,
  disabled,
}: UseControllerProps<T>) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
  } = useController({ control, name })

  /**
   * Get the country list
   */
  const { countries, isLoading, error } = useTradingCountries()

  /**
   * generate Menu to render in the dropdown
   */
  const renderCountry = (item: Country, { handleClick, modifiers }: ItemRendererProps) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.code}
        text={item.name}
        onClick={handleClick}
        roleStructure="listoption"
      />
    )
  }

  /**
   * Filter the country based on the query
   */
  const filterCountry: ItemPredicate<Country> = (query, country, index, exactMatch) => {
    const normalizedTitle = country.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner size={15} />
      ) : (
        <Select
          items={countries?.length ? countries : []} // we want to keep the selected country name displayed in the Button, so if no country list we display emmpty list
          itemRenderer={renderCountry}
          itemPredicate={filterCountry}
          onItemSelect={country => onChange(country)}
          noResults={<MenuItem disabled={true} text="No results" />}
          resetOnSelect={true}
          resetOnClose={true}
        >
          <SelectButton
            text={value ? (value as Country).name : "Select a country"}
            rightIcon="caret-down"
            small
            intent={isDirty ? "warning" : "none"}
            disabled={disabled || !!error}
            icon={disabled && "disable"}
          />
        </Select>
      )}
      <ToastError
        error={error}
        errorMessage="Unable to get countries, please try to refresh the page."
      />
    </>
  )
}
