import { Intent, Tooltip } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import AddNewDatasetDialog from "../components/AddNewDatasetDialog"
import {
  AddTickerButton,
  DetailCard,
  MainContainer,
  SearchContainer,
} from "../components/CommonStyled.styled"
import DatasetSuggest from "../components/DatasetSuggest"
import { useGetTradingDatasetQuery } from "../hooks/useGetTradingDatasetQuery"
import DatasetForm from "./DatasetForm"
import { TradingDataset } from "./types"

const Dataset = () => {
  const [selectedDataset, setSelectedDataset] = useState<TradingDataset | undefined>(undefined)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const { datasetId } = useParams()
  const navigate = useNavigate()
  const { data: dataset, isLoading, error } = useGetTradingDatasetQuery(datasetId || "")

  // Update selected dataset when data is fetched from API
  useEffect(() => {
    if (dataset) {
      setSelectedDataset(dataset)
    }
  }, [dataset])

  // Handle dataset selection from the suggest component
  const handleDatasetSelection = (dataset: TradingDataset | undefined) => {
    if (dataset) {
      // If dataset is selected, update URL to trigger the query
      navigate(`/config/datasets/${dataset.id}`)
    } else {
      // If dataset is cleared, reset the state
      setSelectedDataset(undefined)
    }
  }

  return (
    <MainContainer flexDirection="column" alignItems="center" justifyContent="center" gap="30px">
      <SearchContainer flexDirection="row" alignItems="center" gap="10px">
        <DatasetSuggest
          setSelectedItem={handleDatasetSelection}
          errorMessage="Unable to search datasets, please try to refresh the page."
        />
        <Tooltip content="Add new ticker(s) or create custom dataset">
          <AddTickerButton
            onClick={() => setIsOpenDialog(true)}
            icon="plus"
            intent={Intent.PRIMARY}
            minimal
            outlined
          />
        </Tooltip>
        <AddNewDatasetDialog
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          title="Add Dataset"
        />
      </SearchContainer>

      {selectedDataset && !isLoading && (
        <DetailCard>
          <DatasetForm selectedDataset={selectedDataset} setSelectedDataset={setSelectedDataset} />
        </DetailCard>
      )}
    </MainContainer>
  )
}

export default Dataset
