import { Tab, Tabs } from "@blueprintjs/core"
import { useState, useEffect } from "react"

import { useLocation, useNavigate } from "react-router-dom"
import { capitalizeFirstLetter } from "../../../utils"
import { ConfigContainer } from "./Config.styled"
import Dataset from "./Dataset/Dataset"
import Etf from "./Etf/Etf"
import Stock from "./Stock/Stock"
import { ConfigDisplayTab } from "./types"

const Config = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<ConfigDisplayTab>(ConfigDisplayTab.STOCK)

  useEffect(() => {
    const pathSegments = location.pathname.split("/")
    // Find the index of 'config' and get the next segment as the tab
    const configIndex = pathSegments.findIndex(segment => segment === "config")
    const tabFromPath =
      configIndex >= 0 ? (pathSegments[configIndex + 1] as ConfigDisplayTab) : null
    if (tabFromPath) {
      setSelectedTab(tabFromPath)
    }
  }, [location.pathname])

  return (
    <ConfigContainer flexDirection="column" selectedTab={selectedTab}>
      <Tabs
        id="config-tabs"
        onChange={id => {
          setSelectedTab(id as ConfigDisplayTab)
          navigate(`/config/${id}`)
        }}
        selectedTabId={selectedTab}
        /**
         * renderActiveTabPanelOnly avoids warning:
         *      " AG Grid tried to call sizeColumnsToFit() but the grid is coming back
         *      with zero width, maybe the grid is not visible yet on the screen? "
         */
        renderActiveTabPanelOnly
        large
        animate={false} // prevents the indicator of the selected tab from being misplaced
      >
        <Tab
          id={ConfigDisplayTab.STOCK}
          title={capitalizeFirstLetter(ConfigDisplayTab.STOCK)}
          panel={<Stock />}
        />
        <Tab id={ConfigDisplayTab.ETF} title={ConfigDisplayTab.ETF.toUpperCase()} panel={<Etf />} />
        <Tab
          id={ConfigDisplayTab.DATASET}
          title={capitalizeFirstLetter(ConfigDisplayTab.DATASET)}
          panel={<Dataset />}
        />
      </Tabs>
    </ConfigContainer>
  )
}

export default Config
