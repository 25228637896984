import { Alert, Intent, Spinner } from "@blueprintjs/core"
import { useState } from "react"

import { IconNames } from "@blueprintjs/icons"
import { AxiosError } from "axios"
import { MediumItalicText } from "../../../../styled/text.styled"
import { createToaster } from "../../../utils/createToaster"
import Grid from "../components/Grid"
import { DataframeData } from "../types"
import { RefreshButton } from "./components/RefreshButton"
import { useCancelOrderMutation } from "./hooks/useCancelOrderMutation"
import useIntervalDateDistance from "./hooks/useIntervalDateDistance"
import { NoDataMessage, OrdersContainer } from "./Orders.styled"

interface IOrdersProps {
  orders?: DataframeData
  isLoading: boolean
}

// Define the interface for the order details
interface OrderDetails {
  orderId: string
  side?: string
  quantity?: string | number
  symbol?: string
}

const Orders = ({ orders, isLoading }: IOrdersProps) => {
  // State to manage alert visibility and order details
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [orderToCancel, setOrderToCancel] = useState<OrderDetails | null>(null)

  // Get the cancel order mutation
  const { mutate: cancelOrder } = useCancelOrderMutation({
    onSuccess: data => {
      if (data.success) {
        createToaster({
          message: `Order cancelled successfully`,
          intent: Intent.SUCCESS,
        })
      } else {
        createToaster({
          message: `Failed to cancel order: ${data.description}`,
          intent: Intent.DANGER,
        })
      }
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: `Error cancelling order: ${error.message}`,
        intent: Intent.DANGER,
      })
    },
  })

  // Calculate the time difference for the last updated info
  const ordersDateDistance = useIntervalDateDistance({
    date: orders?.last_updated_at,
    interval: 60 * 1000,
  })

  // Handler for cancel order action from context menu
  const handleCancelOrderRequest = (
    orderId: string,
    side?: string,
    quantity?: string | number,
    symbol?: string,
  ) => {
    if (orderId) {
      setOrderToCancel({ orderId, side, quantity, symbol })
      setIsAlertOpen(true) // Open the alert
    }
  }

  // Function to handle order cancellation
  const handleCancelOrder = () => {
    if (orderToCancel?.orderId) {
      cancelOrder({ orderId: orderToCancel.orderId })
    }
    setIsAlertOpen(false)
    setOrderToCancel(null)
  }

  return (
    <>
      <OrdersContainer flexGrow={1} alignItems="center" justifyContent="center">
        {orders ? (
          <Grid
            rows={orders.rows}
            columns={orders.columns}
            label="Orders"
            contextMenuItems={orders.context_menu_items}
            cancelOrderHandler={handleCancelOrderRequest}
          >
            {ordersDateDistance && (
              <>
                <MediumItalicText>Last updated {ordersDateDistance}</MediumItalicText>
                <RefreshButton dateDistance={ordersDateDistance} />
              </>
            )}
          </Grid>
        ) : isLoading ? (
          <Spinner />
        ) : (
          <NoDataMessage>No orders available, please select an account and/or a book</NoDataMessage>
        )}
      </OrdersContainer>

      {/* Render the alert with enhanced order details */}
      <Alert
        isOpen={isAlertOpen}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={handleCancelOrder}
        onCancel={() => setIsAlertOpen(false)}
        icon={IconNames.CROSS_CIRCLE}
        intent={Intent.DANGER}
        className="bp5-dark"
        canOutsideClickCancel
      >
        <p>Are you sure you want to cancel this order?</p>
        {orderToCancel && (
          <div>
            {orderToCancel.symbol && (
              <p>
                <strong>Symbol:</strong> {orderToCancel.symbol}
              </p>
            )}
            {orderToCancel.side && (
              <p>
                <strong>Side:</strong> {orderToCancel.side}
              </p>
            )}
            {orderToCancel.quantity && (
              <p>
                <strong>Quantity:</strong> {orderToCancel.quantity}
              </p>
            )}
          </div>
        )}
      </Alert>
    </>
  )
}

export default Orders
