import { Button, EditableText, Icon } from "@blueprintjs/core"
import { useQueryClient } from "@tanstack/react-query"
import { isEmpty } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ConfirmDialog } from "../../../components"
import { createToaster } from "../../../utils/createToaster"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"
import Sectors from "../components/Sectors"
import useUpdateTradingDatasetMutation from "../hooks/useUpdateTradingDataset"
import { formatDataset } from "../utils/formatDataset"
import DatasetFields from "./DatasetFields"
import { ActionButtonGroup, FieldsContainer, StockName } from "./DatasetForm.styled"
import DatasetMetadata from "./DatasetMetadata"
import { TradingDataset } from "./types"

interface IDatasetFormProps {
  selectedDataset: TradingDataset
  setSelectedDataset: Dispatch<SetStateAction<TradingDataset>>
}

type confirmDialiogInitialStateProps = {
  open: boolean
  title: string
  dialogText: string
  handleClick: (() => void) | null
  confirmButtonText: string
}

const confirmDialiogInitialState: confirmDialiogInitialStateProps = {
  open: false,
  title: "",
  dialogText: "",
  handleClick: null,
  confirmButtonText: "",
}

const DatasetForm = ({ selectedDataset, setSelectedDataset }: IDatasetFormProps) => {
  const queryClient = useQueryClient()
  const [confirmDialog, setConfirmDialog] = useState(confirmDialiogInitialState)
  // state variable that changes whenever the form is reset. This will trigger a rerender of the EditableText component.
  const [resetKey, setResetKey] = useState(0)

  const methods = useForm<TradingDataset>({
    defaultValues: selectedDataset,
  })

  // Use effect to reset form when selectedStock changes
  useEffect(() => {
    if (selectedDataset) {
      methods.reset(selectedDataset)
    }
  }, [selectedDataset, methods])

  //Mutate function
  const updateDatasetMutation = useUpdateTradingDatasetMutation({
    onSuccess: dataset => {
      setSelectedDataset(dataset)
      // Invalidate the datasets query to refresh the updated dataset
      queryClient.invalidateQueries({ queryKey: ["trading_datasets"] })
      createToaster({
        message: `${selectedDataset.name} Dataset updated with success`,
        intent: "success",
      })
    },
    onError: () => {
      methods.reset()
      createToaster({
        message: `An error occurred updating the Dataset ${selectedDataset.name}, please refresh the page`,
        intent: "danger",
      })
    },
  })

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    (dataset: TradingDataset) => {
      const formattedDataset = formatDataset(dataset)
      updateDatasetMutation.mutate({ datasetId: dataset.id, dataset: formattedDataset })
      setConfirmDialog(confirmDialiogInitialState)
    },
    [updateDatasetMutation],
  )

  const handleSubmitForm = () => {
    setConfirmDialog({
      open: true,
      title: "Update dataset",
      dialogText: "You are going update this dataset",
      handleClick: () => methods.handleSubmit(submitForm)(),
      confirmButtonText: "Update",
    })
  }

  /**
   * Callback to reset Form
   */
  const resetForm = useCallback(() => {
    methods.reset()
    setConfirmDialog(confirmDialiogInitialState)
    setResetKey(prev => prev + 1) // Update resetKey to force rerender
  }, [methods])

  const handleResetForm = () => {
    setConfirmDialog({
      open: true,
      title: "Reset stock",
      dialogText: "You are going to cancel your change.",
      handleClick: resetForm,
      confirmButtonText: "Reset",
    })
  }

  return (
    <FormProvider {...methods}>
      <form>
        <FlexContainer alignItems="center" justifyContent="space-between" gap="15px">
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <Icon icon="label" size={28} />
            <Controller
              control={methods.control}
              name="name"
              rules={{
                required: "Name is required",
              }}
              render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
                <>
                  <StockName isDirty={isDirty}>
                    <EditableText
                      key={resetKey} // Use resetKey as key
                      value={value}
                      onChange={onChange}
                      placeholder="Edit Name"
                      multiline={true}
                    />
                  </StockName>
                  {methods.formState.errors.name && (
                    <ErrorIconWithTooltip errorMessage={methods.formState.errors.name.message} />
                  )}
                </>
              )}
            />
          </FlexContainer>
          <DatasetMetadata selectedDataset={selectedDataset} />
        </FlexContainer>

        <FieldsContainer flexDirection="column" gap="20px">
          <DatasetFields control={methods.control} selectedDataset={selectedDataset} />
          <Sectors />
          <FlexContainer justifyContent="flex-end">
            <ActionButtonGroup large fill>
              <Button text="Reset" icon="reset" onClick={handleResetForm} />
              <Button
                text="Update"
                icon="upload"
                intent="primary"
                onClick={handleSubmitForm}
                disabled={!methods.formState.isDirty || !isEmpty(methods.formState.errors)}
              />
            </ActionButtonGroup>
          </FlexContainer>
        </FieldsContainer>

        <ConfirmDialog
          title={confirmDialog.title}
          dialogText={confirmDialog.dialogText}
          open={confirmDialog.open}
          close={() => setConfirmDialog(confirmDialiogInitialState)}
          handleClick={confirmDialog.handleClick as () => void}
          confirmButtonText={confirmDialog.confirmButtonText}
        />
      </form>
    </FormProvider>
  )
}

export default DatasetForm
