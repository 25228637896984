import { AxiosInstance } from "axios"

import { DatasetUpdatePayload, TradingDataset } from "../views/pages/Config/Dataset/types"
import { EtfUpdatePayload, TradingEtf } from "../views/pages/Config/Etf/types"
import { StockUpdatePayload } from "../views/pages/Config/Stock/types"
import {
  Country,
  Currency,
  Listing,
  Region,
  Sector,
  TradingStock,
} from "../views/pages/Config/types"
import {
  IRefreshTradingDataResponse,
  ITradingData,
  ITradingSummary,
} from "../views/pages/PortfolioManagement/Portfolio/types"
import {
  IFactorModels,
  IRiskExposures,
  IRiskKeyMetrics,
  RiskZoom,
} from "../views/pages/PortfolioManagement/Risks/types"
import { ComplianceRules } from "../views/pages/PortfolioManagement/Trading/types/compliance"
import { Wave } from "../views/pages/PortfolioManagement/Trading/types/wave"
import { ScientTradingAccount, TradingBook } from "../views/pages/PortfolioManagement/types"

const WAVES_PER_PAGE = 15

export interface ITradingAPI {
  getBooks: ({ accountIds }: { accountIds?: number[] }) => Promise<TradingBook[]>
  getTradingAccountsScient: () => Promise<ScientTradingAccount[]>
  getTradingSummary: ({ liveOrders }: { liveOrders: boolean }) => Promise<ITradingSummary>
  getTradingData: ({
    accountBookPairs,
    liveOrders,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    liveOrders: boolean
  }) => Promise<ITradingData>
  refreshTradingData: () => Promise<IRefreshTradingDataResponse>
  cancelOrder: ({ orderId }: { orderId: string }) => Promise<{
    success: boolean
    status_code: string
    description: string
  }>
  getRiskKeyMetrics: ({
    accountBookPairs,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
  }) => Promise<IRiskKeyMetrics>
  getRiskExposures: ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => Promise<IRiskExposures>
  getRiskZoom: ({
    accountBookPairs,
    type,
    category,
  }: {
    type: string
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    category?: string
  }) => Promise<RiskZoom>
  getFactorModels: () => Promise<IFactorModels>
  getTradeWaves: (pageParam?: string) => Promise<{
    data: Wave[]
    next_cursor: string | null
  }>
  create: (values: Partial<Wave>) => Promise<Wave>
  getComplianceRules: () => Promise<ComplianceRules>
  getTradingCurrencies: () => Promise<Currency[]>
  getTradingCountries: () => Promise<Country[]>
  getTradingSectors: () => Promise<Sector[]>
  getTradingStocks: ({ query }: { query: string }) => Promise<TradingStock[]>
  getTradingRegions: () => Promise<Region[]>
  updateTradingStock: ({
    stockId,
    stock,
  }: {
    stockId: number
    stock: StockUpdatePayload
  }) => Promise<TradingStock>
  getTradingDatasets: ({
    query,
    withPagination,
  }: {
    query: string
    withPagination: boolean
  }) => Promise<{
    data: TradingDataset[]
    next_cursor: string | null
  }>
  getTradingDataset: (datasetId: string) => Promise<TradingDataset>
  getTradingListings: ({ query }: { query: string }) => Promise<Listing[]>
  updateTradingDataset: ({
    datasetId,
    dataset,
  }: {
    datasetId: number
    dataset: DatasetUpdatePayload
  }) => Promise<TradingDataset>
  getTradingEtfs: ({ query }: { query: string }) => Promise<TradingEtf[]>
  updateTradingEtf: ({
    etfId,
    etf,
  }: {
    etfId: number
    etf: EtfUpdatePayload
  }) => Promise<TradingEtf>
  createDataset: (dataset: { name: string; scient_code: string }) => Promise<TradingDataset>
}

const trading = (axiosClient: AxiosInstance): ITradingAPI => ({
  getBooks: async ({ accountIds }: { accountIds?: number[] }) => {
    const { data } = await axiosClient.get<TradingBook[]>("/trading/books", {
      params: { account_id: accountIds },
    })

    return data
  },
  getTradingAccountsScient: async () => {
    const { data } = await axiosClient.get<ScientTradingAccount[]>("/trading/accounts")

    return data
  },
  getTradingSummary: async ({ liveOrders }: { liveOrders: boolean }) => {
    const { data } = await axiosClient.get<ITradingSummary>("/trading/summary", {
      params: { live_orders: liveOrders },
    })

    return data
  },
  getTradingData: async ({
    accountBookPairs,
    liveOrders,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    liveOrders: boolean
  }) => {
    const { data } = await axiosClient.get<ITradingData>("/trading", {
      params: {
        account_book_pairs: JSON.stringify(accountBookPairs),
        live_orders: liveOrders,
      },
    })

    return data
  },
  /**
   * POST /trading/refresh/
   */
  refreshTradingData: async () => {
    const { data } = await axiosClient.post<IRefreshTradingDataResponse>("/trading/refresh/")

    return data
  },
  getRiskKeyMetrics: async ({
    accountBookPairs,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
  }) => {
    const { data } = await axiosClient.get<IRiskKeyMetrics>("/trading/risk/key-metrics", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs) },
    })

    return data
  },
  getRiskExposures: async ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => {
    const { data } = await axiosClient.get<IRiskExposures>("/trading/risk/exposures", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs), type },
    })

    return data
  },
  getRiskZoom: async ({
    accountBookPairs,
    type,
    category,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    type: string
    category: string
  }) => {
    const { data } = await axiosClient.get<RiskZoom>("/trading/risk/zoom", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs), type, category },
    })

    return data
  },
  getFactorModels: async () => {
    const { data } = await axiosClient.get<IFactorModels>("/trading/factor-models/")

    return data
  },
  /**
   * GET /trading/waves/
   */
  getTradeWaves: async (pageParam: string) => {
    const { data } = await axiosClient.get<{ data: Wave[]; next_cursor: string }>(
      `/trading/waves/`,
      {
        params: {
          per_page: WAVES_PER_PAGE,
          next_cursor: pageParam,
        },
      },
    )

    return data
  },
  /**
   * POST /waves/
   */
  create: async (values: Partial<Wave>) => {
    const { data } = await axiosClient.post<Wave>("/trading/waves/", values)

    return data
  },

  /**
   * GET /trading/compliance/rules/
   */
  getComplianceRules: async () => {
    const { data } = await axiosClient.get<ComplianceRules>("/trading/compliance/rules/")

    return data
  },

  /**
   * GET /api/v1/trading/currencies/
   */
  getTradingCurrencies: async () => {
    const { data } = await axiosClient.get<Currency[]>("trading/currencies/")

    return data
  },

  /**
   * GET /api/v1/trading/countries/
   */
  getTradingCountries: async () => {
    const { data } = await axiosClient.get<Country[]>("trading/countries/")

    return data
  },

  /**
   * GET /api/v1/trading/sectors/
   */
  getTradingSectors: async () => {
    const { data } = await axiosClient.get<Sector[]>("trading/sectors/")

    return data
  },

  /**
   * GET /api/v1/trading/stocks/
   */
  getTradingStocks: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<TradingStock[]>("trading/stocks/", {
      params: { ticker: query },
    })

    return data
  },

  /**
   * PUT /api/v1/trading/stocks/${stockId}/
   */
  updateTradingStock: async ({
    stockId,
    stock,
  }: {
    stockId: number
    stock: StockUpdatePayload
  }) => {
    const { data } = await axiosClient.put<TradingStock>(`trading/stocks/${stockId}/`, stock)

    return data
  },

  /**
   * GET /api/v1/trading/datasets/${datasetId}/
   */
  getTradingDataset: async (datasetId: string) => {
    const { data } = await axiosClient.get<TradingDataset>(`trading/datasets/${datasetId}/`)
    return data
  },

  /**
   * GET /api/v1/trading/datasets/
   */
  getTradingDatasets: async ({
    query,
    withPagination,
  }: {
    query: string
    withPagination: boolean
  }) => {
    const { data } = await axiosClient.get<{ data: TradingDataset[]; next_cursor: string | null }>(
      "trading/datasets/",
      {
        params: {
          code_startswith: query,
          long_name: query,
          name: query,
          with_pagination: withPagination,
        },
      },
    )

    return data
  },

  /**
   * PUT /api/v1/trading/datasets/${datasetId}/
   */
  updateTradingDataset: async ({
    datasetId,
    dataset,
  }: {
    datasetId: number
    dataset: DatasetUpdatePayload
  }) => {
    const { data } = await axiosClient.put<TradingDataset>(
      `trading/datasets/${datasetId}/`,
      dataset,
    )

    return data
  },

  /**
   * GET /api/v1/trading/etfs/
   */
  getTradingEtfs: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<TradingEtf[]>("trading/etfs/", {
      params: { ticker: query },
    })

    return data
  },

  /**
   * PUT /api/v1/trading/etfs/${etfId}/
   */
  updateTradingEtf: async ({ etfId, etf }: { etfId: number; etf: EtfUpdatePayload }) => {
    const { data } = await axiosClient.put<TradingEtf>(`trading/etfs/${etfId}/`, etf)

    return data
  },

  /**
   * GET /api/v1/trading/regions/
   */
  getTradingRegions: async () => {
    const { data } = await axiosClient.get<Region[]>("trading/regions/")

    return data
  },

  /**
   * GET /api/v1/trading/listings/
   */
  getTradingListings: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<Listing[]>("trading/listings/", {
      params: { ticker: query },
    })

    return data
  },

  /**
   * POST /api/v1/trading/datasets/
   */
  createDataset: async (dataset: { name: string; scient_code: string }) => {
    const { data } = await axiosClient.post<TradingDataset>("trading/datasets/", dataset)
    return data
  },

  /**
   * POST /trading/cancel-order/
   * Cancels an order in FlexTrade
   */
  cancelOrder: async ({ orderId }: { orderId: string }) => {
    const { data } = await axiosClient.post("/trading/cancel-order/", { order_id: orderId })
    return data
  },
})

export default trading
