import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"
import { TradingDataset } from "../Dataset/types"

const useUpdateTradingDatasetMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (dataset: TradingDataset) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.trading.updateTradingDataset, { onSuccess, onError })
}

export default useUpdateTradingDatasetMutation
