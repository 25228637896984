import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../hooks/useApi"
import { TradingDataset } from "../Dataset/types"

const useCreateDatasetMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (dataset: TradingDataset) => void
  onError: (error: AxiosError<{ error: { message: string; code: string } }>) => void
}) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.trading.createDataset, { onSuccess, onError })
}

export default useCreateDatasetMutation
