import { isEqual } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect } from "react"

import { DataframeData, IAccountBookPairs, TradingBook } from "../../types"
import FullScreenGrid from "./FullScreenGrid"
import { useAgGridTreeData } from "./hooks/useAgGridTreeData"

interface SummaryGridProps {
  summary: DataframeData
  books: TradingBook[]
  setSelectedFilters: Dispatch<SetStateAction<IAccountBookPairs>>
  selectedFilters: IAccountBookPairs
  label?: string
  children?: React.ReactNode
}

const SummaryGrid = ({
  summary,
  books,
  setSelectedFilters,
  selectedFilters,
  label,
  children,
}: SummaryGridProps) => {
  const {
    gridRef,
    sizeColumnsToFit,
    defaultColDef,
    columnsData,
    onModelUpdated,
    getContextMenuItems,
  } = useAgGridTreeData({
    columns: summary.columns,
    rows: summary.rows,
  })

  /**
   * Handles external selectedFilters to ag grid API selection
   */
  const filtersStateToAgGridState = useCallback(() => {
    gridRef.current?.api?.forEachNode(node => {
      if (
        selectedFilters.some(selectedFilter => {
          const book = books.find(book => book.code === node.data["Book"])
          const account = book?.trading_account_scients.find(
            account => account.name === node.data["Account"],
          )
          return (
            selectedFilter.account_id === account?.id &&
            (!selectedFilter.book_ids ||
              selectedFilter.book_ids?.some(book_id => book_id === book?.id))
          )
        })
      ) {
        /**
         * We need to use agg grid API to avoid race conditions on rows data generation
         */
        node.setSelected(true)
      } else {
        node.setSelected(false)
      }
    })
  }, [books, gridRef, selectedFilters])

  /**
   * Fill selectedFilters when user select rows
   * If users unselects all row, so selectedFilters=[] and all available tradingData are loaded
   */
  const onSelectionChanged = useCallback(() => {
    /**
     * We first get all the selected Nodes from ag grid api
     */
    const selectedNodeList = gridRef.current?.api.getSelectedNodes() || []

    const accountBookPairs: IAccountBookPairs = []
    for (const node of selectedNodeList) {
      if (node.level === 0) {
        /**
         * We only build AccountBookPairs for book lines,
         * from the UI selecting an Account will create a
         * selectedNode for all underlying books.
         * All accounts will have at least the (BLANK) book link to it.
         */
        continue
      }
      /**
       * We match account and book from the server state
       * in order to retrieve their ids
       */
      const book = books.find(book => book.code === node.data["Book"])
      const account = book?.trading_account_scients.find(
        account => account.name === node.data["Account"],
      )

      if (!account) {
        /**
         * This case should not happen
         */
        console.error(
          `[Missing Account] - Account ${node.data["Account"]} not found in accounts list`,
        )
        continue
      }

      /**
       * If account is not in accountBookPairs, we add it
       */
      if (!accountBookPairs.some(pair => pair.account_id === account?.id)) {
        accountBookPairs.push({
          account_id: account.id,
          book_ids: book ? [book.id] : [],
        })
      } else {
        /**
         * If account is already in accountBookPairs, we add book to its book_ids
         */
        if (book) {
          accountBookPairs.find(pair => pair.account_id === account?.id)?.book_ids?.push(book.id)
        }
      }
    }

    return setSelectedFilters(accountBookPairsPrev => {
      /**
       * If accountBookPairsPrev and accountBookPairs are equal, we don't update selectedFilters
       * to avoid unnecessary chain re-rendering
       */
      return isEqual(accountBookPairsPrev, accountBookPairs)
        ? accountBookPairsPrev
        : accountBookPairs
    })
  }, [books, gridRef, setSelectedFilters])

  /**
   * When rowsData is updated, we need to select rows that were selected before
   */
  useEffect(() => {
    /**
     * Select rows that are in selectedFilters
     */
    if (summary.rows) {
      filtersStateToAgGridState()
    }
  }, [filtersStateToAgGridState, summary.rows])

  const onFirstDataRender = () => {
    sizeColumnsToFit()
    filtersStateToAgGridState()
  }

  return (
    <FullScreenGrid
      ref={gridRef}
      rowData={summary.rows}
      columnDefs={columnsData}
      defaultColDef={defaultColDef}
      onFirstDataRendered={onFirstDataRender}
      suppressColumnVirtualisation={true}
      autoGroupColumnDef={{
        headerName: "Account / Book",
        minWidth: 250,
        cellRendererParams: {
          checkbox: true,
        },
        menuTabs: [], // Disable menu tabs for Account / Book column
      }}
      rowSelection="multiple"
      groupSelectsChildren={true}
      suppressRowClickSelection={true}
      treeData={true}
      getDataPath={data => data.path}
      onSelectionChanged={onSelectionChanged}
      rowHeight={25}
      suppressCellFocus={true}
      getContextMenuItems={params => getContextMenuItems(params)}
      onModelUpdated={onModelUpdated}
      label={label}
    >
      {children}
    </FullScreenGrid>
  )
}

export default SummaryGrid
