import { DataframeData } from "../types"

export type Exposure = {
  type: string
  dataframe: DataframeData
}

export type IRiskKeyMetrics = {
  key_metrics: DataframeData
}

export type IRiskExposures = {
  exposures: Exposure[]
}

export type PositionRiskZoom = {
  long: DataframeData
  short: DataframeData
}

// Define the structure of the data returned by the zoom endpoint
export enum RiskZoomDataType {
  LONG_SHORT = "long_short", // long short position
  STYLES = "styles", //  single style dataframe
}

// Define descriminated union type for zoom data based on the data type
export type RiskZoom =
  | { data_type: RiskZoomDataType.LONG_SHORT; data: PositionRiskZoom }
  | { data_type: RiskZoomDataType.STYLES; data: DataframeData }

/** Define all the available risk exposure types */
export enum RiskType {
  SECTOR_LEVEL_1 = "B_L1",
  SECTOR_LEVEL_2 = "B_L2",
  SECTOR_LEVEL_3 = "B_L3",
  CYCLICALITY = "B_CND",
  CURRENCIES = "B_CUR",
  GEO = "B_GEO",
  STYLES = "B_STYLES",
}

// Define the factor model time zones
export enum FactorModelTimeZones {
  EUROPE_AFRICA = "europe_africa",
  AMERICAS = "americas",
  ASIA_OCENIA = "asia_oceania",
}

// Define the factor model for a specific time zone
export type FactorModel = {
  timezone: FactorModelTimeZones
  factor_returns: DataframeData
  goodness_of_fit: DataframeData
  best_factors: DataframeData
}

// Define the structure of the factor models response
export type IFactorModels = {
  factor_models: FactorModel[]
}
