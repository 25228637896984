import { MenuItem, Spinner } from "@blueprintjs/core"
import { ItemPredicate, ItemRendererProps, Select } from "@blueprintjs/select"
import { useController, UseControllerProps } from "react-hook-form"

import { useMemo } from "react"
import { ToastError } from "../../../components"
import useTradingRegions from "../hooks/useTradingRegions"
import { SubRegion } from "../types"
import { SelectButton } from "./SelectComponent.styled"

export const SubRegionSelect = <T extends Record<string, any>>({
  control,
  name,
  disabled,
}: UseControllerProps<T>) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
  } = useController({ control, name })

  /**
   * Get the country list
   */
  const { regions, isLoading, error } = useTradingRegions()

  /**
   * Extracted sub regions from the regions
   */
  const subRegions = useMemo(() => regions?.flatMap(region => region.sub_regions) ?? [], [regions])

  /**
   * generate Menu to render in the dropdown
   */
  const renderSubRegion = (item: SubRegion, { handleClick, modifiers }: ItemRendererProps) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={item.name}
        onClick={handleClick}
        roleStructure="listoption"
      />
    )
  }

  /**
   * Filter the country based on the query
   */
  const filterSubRegion: ItemPredicate<SubRegion> = (query, subRegion, index, exactMatch) => {
    const normalizedTitle = subRegion.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner size={15} />
      ) : (
        <Select
          items={subRegions?.length ? subRegions : []} // we want to keep the selected country name displayed in the Button, so if no country list we display emmpty list
          itemRenderer={renderSubRegion}
          itemPredicate={filterSubRegion}
          onItemSelect={subRegion => onChange(subRegion)}
          noResults={<MenuItem disabled={true} text="No results" />}
          resetOnSelect={true}
          resetOnClose={true}
        >
          <SelectButton
            text={value ? (value as SubRegion).name : "Select a sub region"}
            rightIcon="caret-down"
            small
            intent={isDirty ? "warning" : "none"}
            disabled={disabled || !!error}
            icon={disabled && "disable"}
          />
        </Select>
      )}
      <ToastError
        error={error}
        errorMessage="Unable to get sub regions, please try to refresh the page."
      />
    </>
  )
}
