import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { TradingDataset } from "../Dataset/types"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ToastError } from "../../../components"
import { useDebounce } from "../../../hooks/useDebounce"
import useTradingDatasets from "../hooks/useTradingDatasets"
import { LongNameSpan } from "./DatasetSuggest.styled"
import { ItemsContainer, Title } from "./TickerSuggest.styled"

interface ITickerSuggestProps {
  setSelectedItem:
    | Dispatch<SetStateAction<TradingDataset | undefined>>
    | ((dataset: TradingDataset | undefined) => void)
  errorMessage?: string
}

/**
 * Suggest component that allows searching and selecting tickers
 */
const DatasetSuggest = ({
  setSelectedItem,
  errorMessage = "Unable to search datasets, please try to refresh the page.",
}: ITickerSuggestProps) => {
  const [query, setQuery] = useState<string>("")
  const { datasets, isLoading, error } = useTradingDatasets({ query, withPagination: false })

  const inputRef = useRef<HTMLInputElement | null>(null)
  // Set the focus on the input
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }, [])

  // Create a debounced function to update the query state
  const debouncedSetQuery = useDebounce((newQuery: string) => {
    setQuery(newQuery)
  }, 500) // Debounce delay in ms

  /**
   * generate Menu to render in the dropdown
   */
  const renderTickers: ItemRenderer<TradingDataset> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={
          <FlexContainer flexDirection="column">
            <Title>{item.name}</Title>
            {/* Show listings only if queryLength is equal to 2 characters or more */}
            {query.length >= 2 && (
              <ItemsContainer flexDirection="column">
                {item.bloomberg_code || item.scient_code ? (
                  <span>{item.bloomberg_code || item.scient_code}</span>
                ) : (
                  <span>No ticker found</span>
                )}
                {item.long_name && <LongNameSpan>{item.long_name}</LongNameSpan>}
              </ItemsContainer>
            )}
          </FlexContainer>
        }
      />
    )
  }

  /**
   * Filter the ticker based on the query
   */
  const filterTickers: ItemPredicate<TradingDataset> = (query, item, _index, exactMatch) => {
    // Show "No ticker found" if query is less than 2 characters
    if (query.length < 2) {
      return false
    }

    const upperQueries = query
      .toUpperCase()
      .split(",")
      .map(q => q.trim())
      .filter(q => q.length > 0) // Filter out empty queries after splitting

    // If no valid queries after filtering, return false
    if (upperQueries.length === 0) {
      return false
    }

    return upperQueries.some(upperQuery => {
      // Check if any of the fields contain the query
      const matchesBloombergCode = item.bloomberg_code
        ? item.bloomberg_code.toUpperCase().includes(upperQuery)
        : false

      const matchesScientCode = item.scient_code
        ? item.scient_code.toUpperCase().includes(upperQuery)
        : false

      const matchesName = item.name ? item.name.toUpperCase().includes(upperQuery) : false

      const matchesLongName = item.long_name
        ? item.long_name.toUpperCase().includes(upperQuery)
        : false

      return matchesBloombergCode || matchesScientCode || matchesName || matchesLongName
    })
  }

  return (
    <>
      <Suggest
        items={datasets?.data ?? []}
        itemRenderer={renderTickers}
        itemPredicate={filterTickers}
        onItemSelect={item => setSelectedItem(item)}
        noResults={
          <MenuItem
            disabled
            text={
              query.length < 2
                ? "Start typing to search..."
                : isLoading
                  ? "Loading..."
                  : "No datasets found"
            }
          />
        }
        inputValueRenderer={item => ""} // returns empty string because we don't want to display Ticker name after selected
        inputProps={{
          placeholder: "Search by ticker, name or description...",
          large: true,
          inputRef: inputRef,
        }}
        query={query}
        onQueryChange={debouncedSetQuery} // Update query on input change
        resetOnSelect
        resetOnClose
        fill
      />
      {error && <ToastError error={error} errorMessage={errorMessage} />}
    </>
  )
}

export default DatasetSuggest
