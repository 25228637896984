import {
  Alignment,
  Button,
  Classes,
  Menu,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  Popover,
} from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import * as R from "ramda"
import { Fragment, useMemo } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"

import uiDuck from "../../state/modules/ui"
import { FlexContainer } from "../../styled/flexbox.styled"
import { SCIENT_ROUTES } from "../Routes"
import { ScientLogo } from "../components"
import { QuickChart } from "../components/QuickChart/QuickChart"
import { useAuth, useWindowDimensions } from "../hooks"
import { useGlobalState } from "../hooks/useGlobalState"
import { CustomMenuItem, IdeasDotCounter, NavContainer } from "./Nav.styled"
import NavButton from "./NavButton"

interface INavProps {
  sidebar: boolean
  toggleSidebar: () => void
}

const Nav = ({ sidebar, toggleSidebar }: INavProps) => {
  const { pathname } = useLocation()
  const { ideasStats } = useGlobalState()
  const { logout, profile } = useAuth()
  const navigate = useNavigate()

  /**
   * Get ideas stats in the context in order to display unseen idea counter in the nav bar
   */
  const unseenIdeas = useMemo(
    () => ideasStats?.unseen_ideas_count,
    [ideasStats?.unseen_ideas_count],
  )
  const unseenCount = useMemo(
    () => (!unseenIdeas ? "" : unseenIdeas < 100 ? unseenIdeas.toString() : "99+"),
    [unseenIdeas],
  )

  /**
   * Retrive screen's width to set how many navButton are visible
   */
  const { width } = useWindowDimensions()

  const navButtonList = [
    { icon: IconNames.DESKTOP, route: SCIENT_ROUTES.DESKS, text: "Desks" },
    { icon: IconNames.SERIES_SEARCH, route: SCIENT_ROUTES.DATASETS, text: "Datasets" },
    { icon: IconNames.TIMELINE_EVENTS, route: SCIENT_ROUTES.EVENTS, text: "Events" },
    { icon: IconNames.REGRESSION_CHART, route: SCIENT_ROUTES.ANALYSES, text: "Analysis" },
    { icon: IconNames.OFFICE, route: SCIENT_ROUTES.STOCKS, text: "Stocks" },
    {
      icon: IconNames.LIGHTBULB,
      route: SCIENT_ROUTES.IDEAS,
      text: "Ideas",
      withIdeasDotCounter: true,
    },
    { icon: IconNames.NOTIFICATIONS, route: SCIENT_ROUTES.NOC, text: "Noc" },
    {
      icon: IconNames.CHART,
      route: SCIENT_ROUTES.PORTFOLIO_MANAGEMENT,
      text: "Portfolio Management",
    },
    { icon: IconNames.WRENCH, route: SCIENT_ROUTES.CONFIG, text: "Config" },
    { icon: IconNames.HELP, route: SCIENT_ROUTES.ADDIN, text: "Addin" },
  ]

  // Determine how many buttons should be visible
  const numberOfvisibleButtons =
    width > 1200 ? navButtonList.length : width > 970 ? 7 : width > 768 ? 5 : width > 630 ? 3 : 0

  const visibleNavButtons = navButtonList.slice(0, numberOfvisibleButtons)
  const hiddenNavButtons = navButtonList.slice(numberOfvisibleButtons)

  return (
    <NavContainer>
      <Navbar fixedToTop>
        <NavbarGroup align={Alignment.LEFT}>
          <Link to={{ pathname: SCIENT_ROUTES.HOME }}>
            <ScientLogo
              color="white"
              style={{ height: "28px", opacity: "0.3", marginRight: "4px" }}
            />
          </Link>
          {profile?.groups.includes("admin") && <QuickChart />}
          <NavbarDivider />
          {visibleNavButtons.map((button, index) => (
            <Fragment key={index}>
              <NavButton icon={button.icon} to={{ pathname: button.route }} text={button.text} />
              {button.withIdeasDotCounter && unseenCount && (
                <IdeasDotCounter>{unseenCount}</IdeasDotCounter>
              )}
            </Fragment>
          ))}
          {hiddenNavButtons.length > 0 && (
            <Popover
              content={
                <Menu>
                  {hiddenNavButtons.map((button, index) => (
                    <FlexContainer key={index}>
                      <CustomMenuItem
                        icon={button.icon}
                        text={button.text}
                        href={button.route}
                        active={pathname.startsWith(button.route)}
                      />
                      {button.withIdeasDotCounter && unseenCount && (
                        <IdeasDotCounter isInMenuItem={true}>{unseenCount}</IdeasDotCounter>
                      )}
                    </FlexContainer>
                  ))}
                </Menu>
              }
            >
              <Button icon={IconNames.DOUBLE_CHEVRON_RIGHT} minimal />
            </Popover>
          )}
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <Popover
            interactionKind="hover"
            content={
              <Menu>
                <MenuItem disabled text={`Connected as ${profile?.username}`} />
                <MenuItem
                  icon={IconNames.CELL_TOWER}
                  color={profile?.has_sapi_identity ? "success" : "danger"}
                  text={`${profile?.has_sapi_identity ? "SAPI: Connected" : "SAPI: Not connected"}`}
                  onClick={() => navigate(SCIENT_ROUTES.BLOOMBERG_AUTH)}
                />
                <MenuItem icon="log-out" onClick={() => logout && logout()} text="Log out" />
              </Menu>
            }
            placement="bottom-start"
          >
            <Button icon={IconNames.USER} text="Account" minimal />
          </Popover>
          {[SCIENT_ROUTES.EVENTS, SCIENT_ROUTES.DATASETS].includes(pathname) && (
            <Button
              className={Classes.MINIMAL}
              onClick={() => toggleSidebar()}
              icon={sidebar ? "chevron-right" : "chevron-left"}
            />
          )}
        </NavbarGroup>
      </Navbar>
    </NavContainer>
  )
}

export default connect(R.prop("ui"), { ...uiDuck.actions })(Nav)
