import { Button, EditableText, Icon } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

import { useQueryClient } from "@tanstack/react-query"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ConfirmDialog } from "../../../components"
import { createToaster } from "../../../utils/createToaster"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"
import Sectors from "../components/Sectors"
import { ActionButtonGroup, FieldsContainer, NameContainer } from "../Etf/EtfForm.styled"
import useUpdateTradingEtfMutation from "../hooks/useUpdateTradingEtfMutation"
import { formatEtf } from "../utils/formatEtf"
import { EtfName } from "./EtfForm.styled"
import EtfMetadata from "./EtfMetadata"
import { TradingEtf } from "./types"

interface IEtfFormProps {
  selectedEtf: TradingEtf
  setSelectedEtf: Dispatch<SetStateAction<TradingEtf>>
}

type confirmDialiogInitialStateProps = {
  open: boolean
  title: string
  dialogText: string
  handleClick: (() => void) | null
  confirmButtonText: string
}

const confirmDialogInitialState: confirmDialiogInitialStateProps = {
  open: false,
  title: "",
  dialogText: "",
  handleClick: null,
  confirmButtonText: "",
}

const EtfForm = ({ selectedEtf, setSelectedEtf }: IEtfFormProps) => {
  const queryClient = useQueryClient()
  const [confirmDialog, setConfirmDialog] = useState(confirmDialogInitialState)
  // state variable that changes whenever the form is reset. This will trigger a rerender of the EditableText component.
  const [resetKey, setResetKey] = useState(0)

  const methods = useForm<TradingEtf>({
    defaultValues: selectedEtf,
  })

  // Use effect to reset form when selectedEtf changes
  useEffect(() => {
    if (selectedEtf) {
      methods.reset(selectedEtf)
    }
  }, [selectedEtf, methods])

  //Mutate function
  const updateEtfMutation = useUpdateTradingEtfMutation({
    onSuccess: etf => {
      setSelectedEtf(etf)
      // Invalidate the etfs query to refresh the updated etf
      queryClient.invalidateQueries({ queryKey: ["trading_etfs"] })
      createToaster({
        message: `${selectedEtf.name} ETF updated with success`,
        intent: "success",
      })
    },
    onError: () => {
      methods.reset()
      createToaster({
        message: `An error occurred updating the ETF ${selectedEtf.name}, please refresh the page`,
        intent: "danger",
      })
    },
  })

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    (etf: TradingEtf) => {
      const formattedEtf = formatEtf(etf)
      updateEtfMutation.mutate({ etfId: etf.id, etf: formattedEtf })
      setConfirmDialog(confirmDialogInitialState)
    },
    [updateEtfMutation],
  )

  const handleSubmitForm = () => {
    setConfirmDialog({
      open: true,
      title: "Update ETF",
      dialogText: "You are going update this ETF",
      handleClick: () => methods.handleSubmit(submitForm)(),
      confirmButtonText: "Update",
    })
  }

  /**
   * Callback to reset Form
   */
  const resetForm = useCallback(() => {
    methods.reset()
    setConfirmDialog(confirmDialogInitialState)
    setResetKey(prev => prev + 1) // Update resetKey to force rerender
  }, [methods])

  const handleResetForm = () => {
    setConfirmDialog({
      open: true,
      title: "Reset ETF",
      dialogText: "You are going to cancel your change.",
      handleClick: resetForm,
      confirmButtonText: "Reset",
    })
  }

  return (
    <FormProvider {...methods}>
      <form>
        <FlexContainer justifyContent="flex-end">
          <EtfMetadata selectedEtf={selectedEtf} />
        </FlexContainer>
        <NameContainer alignItems="center">
          <Icon icon="label" size={28} />
          <Controller
            control={methods.control}
            name="name"
            rules={{
              required: "Name is required",
            }}
            render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
              <>
                <EtfName isDirty={isDirty}>
                  <EditableText
                    key={resetKey} // Use resetKey as key
                    value={value}
                    onChange={onChange}
                    placeholder="Edit Name"
                    multiline={true}
                  />
                </EtfName>
                {methods.formState.errors.name && (
                  <ErrorIconWithTooltip errorMessage={methods.formState.errors.name.message} />
                )}
              </>
            )}
          />
        </NameContainer>

        <FieldsContainer flexDirection="column" gap="20px">
          <Sectors />
          {methods.formState.isDirty && (
            <FlexContainer justifyContent="flex-end">
              <ActionButtonGroup large fill>
                <Button text="Reset" icon="reset" onClick={handleResetForm} />
                <Button
                  text="Update"
                  icon="upload"
                  intent="primary"
                  onClick={handleSubmitForm}
                  disabled={!isEmpty(methods.formState.errors)}
                />
              </ActionButtonGroup>
            </FlexContainer>
          )}
        </FieldsContainer>

        <ConfirmDialog
          title={confirmDialog.title}
          dialogText={confirmDialog.dialogText}
          open={confirmDialog.open}
          close={() => setConfirmDialog(confirmDialogInitialState)}
          handleClick={confirmDialog.handleClick as () => void}
          confirmButtonText={confirmDialog.confirmButtonText}
        />
      </form>
    </FormProvider>
  )
}

export default EtfForm
