import { StockUpdatePayload } from "../Stock/types"
import { TradingStock } from "../types"

/**
 * This function cleans the updated stock before sending it to back-end
 */
export const formatStock = (stock: TradingStock): StockUpdatePayload => {
  const formattedData = {
    name: stock.name,
    headquarter_country_id: stock.headquarter_country ? stock.headquarter_country.id : null,
    reporting_currency_id: stock.reporting_currency ? stock.reporting_currency.id : null,
    cyclicality: stock.cyclicality,
    sectors: [
      {
        id: stock.sectors[0].id,
        level: stock.sectors[0].level,
      },
      {
        id: stock.sectors[1].id,
        level: stock.sectors[1].level,
      },
      {
        id: stock.sectors[2].id,
        level: stock.sectors[2].level,
      },
    ],
  }

  return formattedData
}
