import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"

const useTradingListings = ({ query }: { query: string }) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["trading_listings", { query: query }],
    () => scientApi.trading.getTradingListings({ query: query }),
    {
      retry: 1,
      staleTime: Infinity, // Static list, no need to refresh.
      enabled: query.length >= 2, // Only fetch when query length is >= 2
    },
  )

  return {
    listings: data,
    error: error as AxiosError,
    isLoading,
  }
}

export default useTradingListings
