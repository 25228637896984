import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"
import { TradingDataset } from "../Dataset/types"

export const useGetTradingDatasetQuery = (datasetId: string) => {
  const { scientApi } = useApi()

  return useQuery<TradingDataset, AxiosError>(
    ["dataset", datasetId],
    () => scientApi.trading.getTradingDataset(datasetId),
    {
      enabled: !!datasetId && datasetId !== "",
      retry: 1,
    },
  )
}
