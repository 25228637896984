import Metadata from "../components/Metadata"
import { TradingDataset } from "./types"

interface IDatasetMetadataProps {
  selectedDataset: TradingDataset
}

type MetadataField = {
  headerName: string
  field: keyof TradingDataset
  isDate?: boolean
}

const metadataFields: MetadataField[] = [
  { headerName: "Created", field: "created_at", isDate: true },
  { headerName: "Updated", field: "updated_at", isDate: true },
  { headerName: "Bloomberg Code", field: "bloomberg_code", isDate: false },
  { headerName: "Scient Code", field: "scient_code", isDate: false },
  { headerName: "Future Release Date", field: "future_release_date", isDate: true },
]

/**
 * Shows uneditable dataset metadata
 */

const DatasetMetadata = ({ selectedDataset }: IDatasetMetadataProps) => (
  <Metadata selectedItem={selectedDataset} metadataFields={metadataFields} />
)

export default DatasetMetadata
