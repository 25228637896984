import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"

const useTradingDatasets = ({
  query,
  withPagination = true,
}: {
  query: string
  withPagination?: boolean
}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["trading_datasets", { query: query, withPagination: withPagination }],
    () =>
      scientApi.trading.getTradingDatasets({
        query: query,
        withPagination: withPagination,
      }),
    {
      retry: 1,
      staleTime: Infinity, // Static list, no need to refresh.
      enabled: query.length >= 2, // Only fetch when query length is >= 2
    },
  )

  return {
    datasets: data,
    error: error as AxiosError,
    isLoading,
  }
}

export default useTradingDatasets
