import { Button } from "@blueprintjs/core"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { MediumText } from "../../../../styled/text.styled"

export const RowContainer = styled(FlexContainer)({
  margin: "0 10px",
})

export const SelectContainer = styled(FlexContainer)({
  width: "250px",
})

export const SuggestLabel = styled(MediumText)({
  width: "180px",
})

export const SelectLabel = styled(MediumText)({
  width: "120px",
})

type StyledButtonProps = {
  isDirty: boolean
}

/**
 * Highlight active cyclicality choice if user has modified it,
 * in orrange with black text
 */
export const CyclicalityButton = styled(Button, {
  shouldForwardProp: prop => prop !== "isDirty",
})<StyledButtonProps>(props => ({
  color: props.active
    ? props.isDirty
      ? `${SCIENT_COLORS.darkGray1} !important`
      : "white"
    : "white",
  backgroundColor: props.active
    ? props.isDirty
      ? `${SCIENT_COLORS.orange5} !important`
      : `${SCIENT_COLORS.blue3} !important`
    : "",
}))
