import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRendererProps, Select } from "@blueprintjs/select"
import { useState } from "react"
import { useController, UseControllerProps } from "react-hook-form"

import { ToastError } from "../../../components"
import { useDebounce } from "../../../hooks/useDebounce"
import useTradingListings from "../hooks/useTradingListings"
import { Listing } from "../types"
import { SelectButton } from "./SelectComponent.styled"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"

export const ListingSelect = <T extends Record<string, any>>({
  control,
  name,
  disabled,
  placeholder = "Select a listing",
  rules,
}: UseControllerProps<T> & { placeholder?: string }) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
    formState: { errors },
  } = useController({ control, name, rules })

  const [query, setQuery] = useState<string>("")
  const debouncedSetQuery = useDebounce((newQuery: string) => {
    setQuery(newQuery)
  }, 500)

  const { listings, error } = useTradingListings({ query })

  const renderListing = (item: Listing, { handleClick, modifiers }: ItemRendererProps) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={item.bloomberg_code}
        onClick={handleClick}
        roleStructure="listoption"
      />
    )
  }

  const filterListings: ItemPredicate<Listing> = (query, listing) => {
    if (query.length < 2) return false
    return listing.bloomberg_code.toLowerCase().includes(query.toLowerCase())
  }

  return (
    <>
      <Select
        items={listings || []}
        itemRenderer={renderListing}
        itemPredicate={filterListings}
        onItemSelect={listing => onChange(listing)}
        noResults={
          <MenuItem
            disabled
            text={query.length < 2 ? "Start typing to search..." : "No listings found"}
          />
        }
        onQueryChange={debouncedSetQuery}
        resetOnSelect={true}
        resetOnClose={true}
      >
        <SelectButton
          text={value ? (value as Listing).bloomberg_code : placeholder}
          rightIcon="caret-down"
          small
          intent={isDirty ? "warning" : "none"}
          disabled={disabled || !!error}
          icon={disabled && "disable"}
        />
      </Select>
      {errors[name] && <ErrorIconWithTooltip errorMessage={String(errors[name]?.message)} />}
      <ToastError
        error={error}
        errorMessage="Unable to get listings, please try to refresh the page."
      />
    </>
  )
}

export default ListingSelect
