import { Button, Icon, Tooltip } from "@blueprintjs/core"
import { Control, Controller, UseControllerProps, useFormContext, useWatch } from "react-hook-form"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"
import { CountrySelect } from "../components/CountrySelect"
import { CurrencySelect } from "../components/CurrencySelect"
import ListingSelect from "../components/ListingSelect"
import { RegionSelect } from "../components/RegionSelect"
import { SubRegionSelect } from "../components/SubRegionSelect"
import TickerSuggest from "../components/TickerSuggest"
import useTradingStocks from "../hooks/useTradingStocks"
import { RowContainer, SelectContainer, SelectLabel, SuggestLabel } from "./DatasetFields.styled"
import { TradingDataset } from "./types"

interface IDatasetFieldsProps {
  selectedDataset: TradingDataset
  control: Control<TradingDataset>
}

type GeoName = "country" | "region" | "sub_region"

const DatasetFields = ({ control, selectedDataset }: IDatasetFieldsProps) => {
  const { setValue } = useFormContext()
  /**
   * Check if the dataset is a bloomberg dataset
   */
  const isBloombergDataset =
    !!selectedDataset.bloomberg_code && selectedDataset.source === "bloomberg"

  /**
   * Use the trading stocks hook to search for stocks
   */
  const useStocksWrapper = ({ query }: { query: string }) => {
    const { stocks, isLoading, error } = useTradingStocks({ query })
    return { items: stocks ?? [], isLoading, error }
  }

  const [country, region, sub_region, stock, listing] = useWatch({
    control,
    name: ["country", "region", "sub_region", "stock", "listing"], // Watch multiple fields
  })

  /**
   * Disable field according conditions
   */
  const countryDisabled = isBloombergDataset || !!region || !!sub_region
  const regionDisabled = isBloombergDataset || !!country || !!sub_region
  const subRegionDisabled = isBloombergDataset || !!country || !!region

  const stockDisabled = !!listing
  const listingDisabled = !!stock

  /**
   * Remove geo info, setValue to null and ensure state becomes dirty.
   */
  const handleRemoveGeoInfo = (geoName: string) => {
    setValue(geoName, null, { shouldDirty: true, shouldValidate: true })
  }

  const geoOptions: {
    name: GeoName
    label: string
    disabled: boolean
    Component: React.ComponentType<UseControllerProps<TradingDataset>>
  }[] = [
    { name: "country", label: "Country", disabled: countryDisabled, Component: CountrySelect },
    {
      name: "sub_region",
      label: "Sub Region",
      disabled: subRegionDisabled,
      Component: SubRegionSelect,
    },
    { name: "region", label: "Region", disabled: regionDisabled, Component: RegionSelect },
  ]

  const isGeoInfoEmpty = !country && !region && !sub_region

  return (
    <>
      {geoOptions.map(({ name, label, disabled, Component }) => (
        <RowContainer alignItems="center" gap="25px" key={name}>
          <Icon icon="globe" size={22} />
          <SelectLabel>{label}</SelectLabel>
          <FlexContainer gap="20px" alignItems="center">
            <Component name={name} control={control} disabled={disabled} />
            {!disabled && !isGeoInfoEmpty && (
              <Tooltip content={`Remove selected ${name}`} placement="right">
                <Button icon="cross" onClick={() => handleRemoveGeoInfo(name)} small />
              </Tooltip>
            )}
          </FlexContainer>
        </RowContainer>
      ))}
      <RowContainer alignItems="center" gap="25px">
        <Icon icon="dollar" size={22} />
        <SelectLabel>Currency</SelectLabel>
        <FlexContainer>
          <CurrencySelect name="currency" control={control} disabled={isBloombergDataset} />
        </FlexContainer>
      </RowContainer>
      <RowContainer alignItems="center" gap="25px">
        <Icon icon="chart" size={22} />
        <SuggestLabel>Stock</SuggestLabel>
        <Controller
          control={control}
          name="stock"
          render={({ field: { onChange, value }, fieldState: { isDirty, error } }) => (
            <>
              <TickerSuggest
                useItems={useStocksWrapper}
                selectedItem={value}
                autoFocus={false}
                setSelectedItem={onChange}
                errorMessage="Unable to search items, please try to refresh the page."
                borderColor={isDirty ? SCIENT_COLORS.orange4 : undefined}
                disabled={isBloombergDataset || stockDisabled}
              />
              {error && <ErrorIconWithTooltip errorMessage={error.message} />}
            </>
          )}
        />
      </RowContainer>
      <RowContainer alignItems="center" gap="25px">
        <Icon icon="list" size={22} />
        <SelectLabel>Listing</SelectLabel>
        <SelectContainer>
          <ListingSelect
            name="listing"
            control={control}
            disabled={isBloombergDataset || listingDisabled}
          />
        </SelectContainer>
      </RowContainer>
    </>
  )
}

export default DatasetFields
