import { DatasetUpdatePayload, TradingDataset } from "../Dataset/types"

/**
 * This function cleans the updated dataset before sending it to back-end
 */
export const formatDataset = (dataset: TradingDataset): DatasetUpdatePayload => {
  const formattedData = {
    name: dataset.name,
    currency_id: dataset.currency ? dataset.currency.id : null,
    country_id: dataset.country ? dataset.country.id : null,
    stock_id: dataset.stock ? dataset.stock.id : null,
    listing_id: dataset.listing ? dataset.listing.id : null,
    sub_region_id: dataset.sub_region ? dataset.sub_region.id : null,
    region_id: dataset.region ? dataset.region.id : null,
    sectors:
      dataset.sectors
        ?.filter(sector => sector !== undefined)
        .map(sector => ({
          id: sector?.id,
          level: sector?.level,
        })) || [],
  }

  return formattedData
}
